// Adapted from https://github.com/aaronhayes/react-use-hubspot-form
import { useEffect, useState } from 'react'

import type {
  HubSpotFormProps,
  UseHubSpotFormResponse,
  WindowWithHubspot,
} from './HubSpotForm.types'
import { useHubSpotContext } from './HubSpotProvider'

export const useHubSpotForm = (props): UseHubSpotFormResponse => {
  const [formCreated, setFormCreated] = useState(false)
  const windowWithHubSpot: WindowWithHubspot = typeof window !== 'undefined' && window

  useEffect(() => {
    if (windowWithHubSpot.hbspt?.forms && !formCreated) {
      windowWithHubSpot.hbspt.forms.create(props)
      setFormCreated(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formCreated, props, windowWithHubSpot.hbspt?.forms])

  return { formCreated }
}

const HubSpotForm: React.FC<HubSpotFormProps> = ({
  region,
  portalId,
  formId,
  target = '#hubspot-form',
  dataCy = 'hubspot-form',
}: HubSpotFormProps) => {
  useHubSpotForm({
    region,
    portalId,
    formId,
    target,
  })

  const { isScriptLoaded } = useHubSpotContext()
  // strip off the # from the id selector in the provided target val
  const targetAttribute =
    target.charAt(0) === '#' ? `${target.slice(1, target.length)}` : `${target}`

  return <>{isScriptLoaded && <div id={targetAttribute} data-cy={dataCy} />}</>
}

export default HubSpotForm
