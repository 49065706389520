import type { HubSpotFormProps } from '@components/HubSpotForm'
import { HubSpotForm } from '@components/HubSpotForm'
import { WEB_CONTAINER_VERTICAL_SPACE } from '@knauf-group/ct-designs/themeConfigs/constants'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

import type { HubSpotFormWrapperProps } from './HubSpotForm.types'

const HubSpotFormWrapper: React.FC<HubSpotFormWrapperProps> = ({ ...props }) => {
  const hubSpotFormProps: HubSpotFormProps = {
    ...props.fields,
    dataCy: 'hubspot-form',
    target: `#hubspot-form-${props.sys.id.toLowerCase()}`,
  }

  return (
    <Container sx={WEB_CONTAINER_VERTICAL_SPACE}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <HubSpotForm {...hubSpotFormProps} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default HubSpotFormWrapper
